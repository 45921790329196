import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fadeIn"]

  connect() {
    const observerOptions = {
      threshold: 0.27
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);  // Stop observing once faded in
        }
      });
    }, observerOptions);

    this.fadeInTargets.forEach((el) => observer.observe(el));
  }
}
