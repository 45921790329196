import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scroller", "paragraph", "image", "vline"];

  connect() {
    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener("scroll", this.handleScroll);
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    // Handle paragraph fade-in/out logic with blur and vertical movement
    this.scrollerTargets.forEach((scroller, index) => {
      const paragraph = this.paragraphTargets[index];
      const scrollerRect = scroller.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const scrollerVisibleHeight = scrollerRect.bottom - scrollerRect.top;

      if (scrollerRect.top < windowHeight && scrollerRect.bottom > 0) {
        const startFadeIn = windowHeight * 0.9;
        const fullOpacityAt = windowHeight * 0.5;
        const startFadeOut = scrollerVisibleHeight * 0.4;
        const fadeOutOverlapStart = scrollerVisibleHeight * 0.6;

        const distanceIntoView = Math.min(
          Math.max(windowHeight - scrollerRect.top, 0),
          scrollerVisibleHeight
        );

        let opacity = 0;
        let blur = 0; // Initialize blur value
        let translateY = 40; // Initialize translateY to 40px for upward movement

        if (index === this.paragraphTargets.length - 1) {
          if (distanceIntoView >= fullOpacityAt) {
            opacity = 1;
            blur = 0; // No blur when fully visible
            translateY = 0; // Move to normal position
          } else if (distanceIntoView < fullOpacityAt) {
            opacity = distanceIntoView / fullOpacityAt;
            blur = (1 - opacity) * 5; // Blur increases as opacity decreases (max 5px)
            translateY = 40 - (40 * opacity); // Animate upward as it fades in
          }
        } else {
          if (distanceIntoView >= fullOpacityAt && distanceIntoView <= fadeOutOverlapStart) {
            opacity = 1;
            blur = 0;
            translateY = 0; // Move to normal position
          } else if (distanceIntoView < fullOpacityAt) {
            opacity = distanceIntoView / fullOpacityAt;
            blur = (1 - opacity) * 5; // Blur increases as opacity decreases
            translateY = 40 - (40 * opacity); // Animate upward as it fades in
          } else if (distanceIntoView > fadeOutOverlapStart) {
            const distanceFromFadeOutStart = distanceIntoView - fadeOutOverlapStart;
            const fadeOutRange = scrollerVisibleHeight - fadeOutOverlapStart;
            opacity = 1 - (distanceFromFadeOutStart / fadeOutRange);
            blur = (1 - opacity) * 5; // Blur increases as it fades out
            translateY = -(40 * (1 - opacity)); // Continue moving up as it fades out
          }
        }

        paragraph.style.opacity = opacity.toFixed(2);
        paragraph.style.filter = `blur(${blur}px)`; // Apply blur effect
        paragraph.style.transform = `translateY(${translateY}px)`; // Apply vertical movement
      } else {
        paragraph.style.opacity = 0;
        paragraph.style.filter = "blur(5px)"; // Fully blur when out of view
        paragraph.style.transform = "translateY(40px)"; // Reset to initial 40px when out of view
      }
    });

    // Handle parallax effect and fade-in/out for images
    // this.imageTargets.forEach((image) => {
    //   const pace = parseFloat(image.dataset.pace) || 1;
    //   const scrollPosition = window.scrollY;
    //   const marginTop = -(scrollPosition / pace);
    //   image.style.marginTop = `${marginTop}px`;

    //   const imageRect = image.getBoundingClientRect();
    //   const viewportHeight = window.innerHeight;
    //   const viewportCenter = viewportHeight / 2;

    //   const distanceFromCenter = Math.abs(imageRect.top + imageRect.height / 2 - viewportCenter);
    //   const maxDistance = viewportHeight / 2;

    //   let imageOpacity = 1 - (distanceFromCenter / maxDistance);
    //   imageOpacity = Math.max(0, Math.min(1, imageOpacity));
    //   image.style.opacity = imageOpacity.toFixed(2);
    // });

    // Handle vline height increase as scroller #4 enters view
    const scroller4 = this.scrollerTargets[3]; // Targeting scroller #4
    const scroller4Rect = scroller4.getBoundingClientRect();
    const vline = this.vlineTarget;
    
    if (scroller4Rect.top < window.innerHeight && scroller4Rect.bottom > 0) {
      const scrollProgress = Math.min(
        Math.max((window.innerHeight - scroller4Rect.top) / (scroller4Rect.height * 0.5), 0),
        1
      );

      const newHeight = 50 + (200 - 50) * scrollProgress;
      vline.style.height = `${newHeight}px`;
    } else {
      vline.style.height = "50px";
    }
  }
}
