import { Controller } from "@hotwired/stimulus";
import scrollLock from 'scroll-lock';
import Headroom from "headroom.js";

export default class extends Controller {
  static targets = [
    "subnav",
    "overlay",
    "desktopSubmenu",
    "nested",
    "searchnav"
];

  isOverlayActive() {
    return this.overlayTarget.classList.contains('--is-active');
  }

  isSubnavActive() {
    return this.subnavTarget.classList.contains('--is-active');
  }

  isSearchnavActive() {
    return this.searchnavTarget.classList.contains('--is-active');
  }

  // Toggle Search
  toggleSearch(e) {
    e.preventDefault();
    if(this.overlayTarget.classList.contains('--is-active')) {
      scrollLock.enablePageScroll(); 
    } else {
      scrollLock.disablePageScroll(); 
    }
    
    this.searchnavTarget.classList.toggle('--is-active');
    this.overlayTarget.classList.toggle('--is-active');
    
  }
  // Toggle the show/hide of the subnav container, plus the overlay
  toggleSubnav(e) {    
    if(this.overlayTarget.classList.contains('--is-active')) {
      scrollLock.enablePageScroll(); 
    } else {
      scrollLock.disablePageScroll(); 
    }

    this.subnavTarget.classList.toggle('--is-active');
    this.overlayTarget.classList.toggle('--is-active');
    
    const targetedSubnav = e.target.dataset.submenu;
    if(targetedSubnav) {
      this.toggleNested(targetedSubnav);
    }    
  }

  selectNested(e) {
    const targetedSubnav = e.target.dataset.submenu;
    this.toggleNested(targetedSubnav);
  }

  // Handles the deploy of the nested 
  toggleNested(targetedSubnav) {
    
    
    // Unfurl current
    [...this.desktopSubmenuTargets].forEach((submenu) => {
        submenu.classList.remove('--is-active');
    });

    // Furl selected
    document.getElementById(`${targetedSubnav}-items`).classList.add('--is-active');

    [...this.nestedTargets].forEach((toggle) => {
        toggle.classList.remove('--is-active');
    });

    console.log(targetedSubnav);
    document.getElementById(`${targetedSubnav}-submenu`).classList.add('--is-active');
  }

  connect() {
    const headroom = new Headroom(document.querySelector('#header'), {
      offset: 0,
      classes: {
          // when element is initialised
          initial : "headroom",
          // when scrolling up
          pinned : "headroom--pinned",
          // when scrolling down
          unpinned : "headroom--unpinned",
          // when above offset
          top : "headroom--top",
          // when below offset
          notTop : "headroom--not-top",
          // when at bottom of scroll area
          bottom : "headroom--bottom",
          // when not at bottom of scroll area
          notBottom : "headroom--not-bottom",
          // when frozen method has been called
          frozen: "headroom--frozen",
          // multiple classes are also supported with a space-separated list
          pinned: "headroom--pinned"
      },
      tolerance: {
        up: 20,
        down: 20
      }
    })

    headroom.init()  
  }
}
